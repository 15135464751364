<template>
  <div class="sub-container flex-sb">
    <div class="content col" style="width:14%;">
      <section class="section">
        <h2 class="page-title">컨텐츠 관리</h2>
        <div class="contents-menu-box">
          <h3 class="contents-menu-title">
            <a href="#/notice">공지사항 / 이벤트</a>
          </h3>
          <h3 class="contents-menu-title"><a href="#/banner">배너</a></h3>
          <h3 class="contents-menu-title"><a href="#/fund">모금자리</a></h3>
          <h3 class="contents-menu-title">
            <a href="#/health-column">건강칼럼</a>
          </h3>
          <h3 class="contents-menu-title"><a href="#/faq">FAQ</a></h3>
          <h3 class="contents-menu-title">
            <a href="#/option">지원과목 / 지원항목</a>
          </h3>
        </div>
      </section>
    </div>
    <div class="content col" style="width:86%;">
      <section class="section">
        <h3 class="section-title">{{ contentsTitle }} 상세정보</h3>
        <table class="tbl-view app-detail-info input">
          <colgroup>
            <col class="head" />
            <col class="data" />
            <col class="head" />
            <col class="data" />
          </colgroup>
          <tbody>
          <tr>
            <th >제목</th>
            <td v-bind:colspan="contentsType == 'notice' ? 1 : 3 "><input type="text" class="inp-txt-common full"   v-model="data.title"></td>
            <th v-if="contentsType == 'notice'">분류</th>
            <td v-if="contentsType == 'notice'">
              <div class="select-wrap relation">
                <select v-model="data.type">
                  <option value="공지">공지</option>
                  <option value="이벤트">이벤트</option>
                </select>
              </div>
            </td>
          </tr>
          <tr>
            <th>본문</th>
            <td colspan="3">
<!--              <div ref="toastEditor" id="toastEditor"></div>-->
              <editor  ref="toastEditor"
                       :initialValue="data.memo"
                       :options="editorOptions"
                       height="500px"
                       initialEditType="wysiwyg"

                       previewStyle="vertical" />
            </td>

          </tr>
          </tbody>
        </table>
        <div class="table-foot clearfix">
          <button class="btn-confirm tf-btn" @click="back()">
            <span class="txt">취소</span>
          </button>
          <button class="btn-confirm tf-btn" id="submit" @click="save()">
            <span class="txt">저장</span>
          </button>
        </div>
      </section>
    </div>
  </div>
</template>
<style></style>
<script>
  // import Pagination from "../components/Pagination";
  // import 'codemirror/lib/codemirror.css';
  import '@toast-ui/editor/dist/toastui-editor.css';
  import { Editor } from '@toast-ui/vue-editor';
  import axios from "axios";
export default {
  name: "ContentsDetail",
  components: { editor:Editor},
  data: () => ({
    contentsType: "notice",
    contentsTitle: "공지사항",
    param: {
      page: 0,
      pageSize: 10,
      start: null,
      end: null,
      keyword: null
    },
    nowPage: 1,
    total: 0,
    data: {},
    editorText: 'This is initialValue.',
    editorOptions: {
      hideModeSwitch: true,
      hooks: {
        addImageBlobHook: (blob, callback) => {
          (async () => {
            const formData = new FormData();
            formData.append('file', blob);
            const { data } = await  axios.post(process.env.VUE_APP_BASE_URL + 'upload/image',
                    formData,
                    {
                      headers: {
                        'Content-Type': 'multipart/form-data',
                      },
                    }
            );
            // if (this.data.imageUrl == null || this.data.imageUrl == null) {
            //   this.data.imageUrl = data;
            // }
            console.log(data.data);
            callback(data.data, "iamge");
          })();
          return false;
        }
      }
    }
  }),
  mounted() {

    console.log(this.data.memo);
    // this.editor.addHook("addImageBlobHook", (blob, callback) => {
    //   (async () => {
    //     const formData = new FormData();
    //     formData.append('file', blob);
    //     const { data } = await this.$axios.post('/upload/image',
    //             formData,
    //             {
    //               headers: {
    //                 'Content-Type': 'multipart/form-data',
    //               },
    //             }
    //     );
    //     if (this.data.imageUrl == null || this.data.imageUrl == null) {
    //       this.data.imageUrl = data;
    //     }
    //     callback(data, "iamge");
    //   })();
    //
    //   return false;
    // });

    if (this.$route.path == "/notice") {
      this.contentsType = "notice";
      this.contentsTitle = "공지사항";
    } else if (this.$route.path == "/fund") {
      this.contentsType = "fund";
      this.contentsTitle = "모금자리";
    } else if (this.$route.path == "/health-column") {
      this.contentsType = "health-column";
      this.contentsTitle = "건강칼럼";
    } else if (this.$route.path == "/faq") {
      this.contentsType = "faq";
      this.contentsTitle = "FAQ";
    } else if (this.$route.path == "/support") {
      this.contentsType = "support";
      this.contentsTitle = "지원과목/지원항목";
    }
    console.log(this.$route.path);
  },
  beforeMount() {
    console.log(this.$route.params);
    this.id = this.$route.params.id ;
    console.log("this.contentsId  : " + this.id );
    if (this.$route.path.indexOf("/notice")>= 0 ) {
      this.contentsType = "notice";
      this.contentsTitle = "공지사항";
    } else if (this.$route.path.indexOf("/banner") >=0 ) {
      this.contentsType = "banner";
      this.contentsTitle = "배너";
    } else if (this.$route.path.indexOf("/fund") >=0 ) {
      this.contentsType = "fund";
      this.contentsTitle = "모금자리";
    } else if (this.$route.path.indexOf("/health-column") >=0 ) {
      this.contentsType = "health-column";
      this.contentsTitle = "건강칼럼";
    } else if (this.$route.path.indexOf("/faq") >= 0) {
      this.contentsType = "faq";
      this.contentsTitle = "FAQ";
    } else if (this.$route.path.indexOf("/support") >= 0) {
      this.contentsType = "support";
      this.contentsTitle = "지원과목/지원항목";
    }
    if (this.id != undefined ) {
      this.getData();
    }
   },

  methods: {
    uploadImage(blob) {
      (async () => {
        const formData = new FormData();
        formData.append('file', blob);
        const { data } = await this.$axios.post('/upload/image',
                formData,
                {
                  headers: {
                    'Content-Type': 'multipart/form-data',
                  },
                }
        );
        if (this.data.imageUrl == null || this.data.imageUrl == null) {
          this.data.imageUrl = data;
        }
        return data;
      })();
    },
    request() {

      if (this.contentsType == "notice") {
        return  this.$axios.get("notice/" + this.id  );
      } else if (this.contentsType == "banner") {
        return  this.$axios.get("banner/" + this.id );
      } else if (this.contentsType == "fund") {
        return  this.$axios.get("fund/" + this.id );
      } else if (this.contentsType == "health-column") {
        return  this.$axios.get("health-column/" + this.id );
      } else if (this.contentsType == "faq") {
        return  this.$axios.get("faq/" + this.id );
      } else if (this.contentsType == "support") {
        return  this.$axios.get("support/" + this.id );
      }
    },
    async getData() {
      const getDataPromise = await this.request();
      const [response] = await Promise.all([getDataPromise]);

      if (response.data.success) {
        this.data = response.data.data;
        this.$refs.toastEditor.invoke('setMarkdown', this.data.memo);
        console.log("data : ",this.data);
      }
    },
    save() {
      var url = "";
      if (this.contentsType == "notice") {
        url = "notice" ;
      } else if (this.contentsType == "banner") {
        url = "banner" ;
      } else if (this.contentsType == "fund") {
        url = "fund" ;
      } else if (this.contentsType == "health-column") {
        url = "health-column" ;
      } else if (this.contentsType == "faq") {
        url = "faq" ;
      } else if (this.contentsType == "support") {
        url = "support" ;
      }

      this.data.memo = this.$refs.toastEditor.invoke('getHTML');

      var param = this.data;


      var re = new RegExp(/src="(.[^"]+)"/,"g");
      const srcList = re.exec(this.data.memo);
      if (srcList != null && srcList.length > 1) {
        param.imageUrl = srcList[1];
        console.log(param.imageUrl );
      }


      if (this.id != undefined) {
        this.$axios
                .put(url, param)
                .then(response => {
                  if (response.data.success) {
                    this.$alert.show(response.data.message);
                    this.$router.push({ path: '/' + url + '/' + this.id});

                  } else {
                    this.$alert.show(response.data.message);
                  }
                })
                .catch(ex => {
                  if (ex.response.data.message.constructor == Object) {
                    this.$alert.show(ex.response.data.message);
                  }
                });
      } else {
        this.$axios
                .post(url, param)
                .then(response => {
                  if (response.data.success) {
                    this.$alert.show(response.data.message);
                    this.$router.push({ path: '/' + url });

                    //this.$router.push({ path: '/hospital/' + this.hospital.hospitalId});

                  } else {
                    this.$alert.show(response.data.message);
                  }
                })
                .catch(ex => {
                  if (ex.response.data.message.constructor == Object) {
                    this.$alert.show(ex.response.data.message);
                  }
                });
      }

    },
    back() {
      history.back();
    }
  }
};
</script>
